(function ($) {
    $(document).ready(function () {
        teamMemberCarousel();
        quoteSlider();
        featuredVideos();
        magnificVideoLightbox();
        smoothScroll();
    });

    function teamMemberCarousel() {
        let $teamMemberSlider = $('.team-member-slider');
        if($teamMemberSlider.length) {
            $teamMemberSlider.owlCarousel({
                loop: true,
                nav: false,
                margin: 15,
                dots: true,
                //autoplay: true,
                //autoplayTimeout: 10000,
                autoplayHoverPause: true,
                responsive: {
                    0:{
                        items: 1
                    },
                    400:{
                        items: 2
                    },
                    768:{
                        items: 3
                    },
                    992: {
                        items: 4
                    }
                }
            });
        }
    }

    function quoteSlider() {
        let $quoteSlider = $('.quote-slider');
        if($quoteSlider.length) {
            $quoteSlider.owlCarousel({
                loop: true,
                nav: false,
                dots: true,
                //autoplay: true,
                //autoplayTimeout: 10000,
                items: 1,
                autoplayHoverPause: true,
            });
        }
    }

    function featuredVideos() {
        let $featuredVideos = $('body').find('.featured-youtube-video');
        $featuredVideos.each(function() {
            let $videoContainer = $(this).find('.magic-container');
            let $videoWidget = $videoContainer.find('.wpb_video_widget');
            let $iframe = $videoContainer.find('iframe');

            $videoContainer.click(function(event) {
                $iframe[0].src += "&autoplay=1";
                event.preventDefault();
                $videoWidget.addClass('show');
                $videoContainer.addClass('fade-play-btn');

                // Style set to 1s transtion
                setTimeout(function() {
                    $videoContainer.addClass('hide-play-btn');
                }, 1000)
            });
        });
    }

    function magnificVideoLightbox() {
        $('body').find('.video-popup-btn').each(function() {
            $(this).magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });
        });
    }

    /**
     * Scroll to animation for anchor links contained within page. Kleo has it's own function for the header.
     */
    function smoothScroll() {
        let headerHeight = $('.navbar-header').height();

        // Select all links with hashes
        $('#main a[href*="#"]')
        // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('.accordion-toggle')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - headerHeight
                        }, 1000);
                    }
                }
            });
    }
}(jQuery));
